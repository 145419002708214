import $ from 'jquery';
import logger from 'OK/logger';
import { ajax, updateBlockModelCallback } from 'OK/utils/utils';
import { getPlayerId, isSilent } from 'OK/OKVideo';
import { myTrackerService } from 'OK/metrics/MyTrackerService';
import 'jquery.okTags';

var URL_MOVE_MOVIE = '/dk?cmd=AltGroupAlbumCardBlock&st.cmd=altGroupVideoAll',
    URL_SORT_MOVIE = '/dk?cmd=videoCommand&a=changeAlbumMoviePosition',
    URL_RENDER_RELATED = '/dk?cmd=RelatedFeedMovies',
    LOG_RELATED = 'video.related',
    LOG_GROUP_DND = 'groups.video-dnd',
    LOG_DRAG = 'gv-drag',
    LOG_DROP_ERROR = 'gv-error-drop',
    LOG_DROP_ALBUM = 'gv-drop-album',
    LOG_DROP_LEFT_MENU = 'gv-drop-left-menu',
    LOG_SORT_START = 'gv-sort-start',
    LOG_SORT_SUCCESS = 'gv-sort-success',
    LOG_SORT_ERROR = 'gv-sort-error';

var defaultDNDparams = {
    revert: 'invalid',
    scroll: true,
    scrollSpeed: 50,
    cursor: 'move',
    cursorAt: {top: 48, left: 82},
    opacity: .8,
    zIndex: 20,
    helper: function (event, $element) {
        $element = $element || $(event.currentTarget);
        var $img = $element.find('.video-card_img'),
            $imgWrapper = $element.find('.video-card_img-w'),
            $draggableItem = $('<div/>', {
                'class' : 'draggable-item __video',
                'width': Math.floor($imgWrapper.width() / 2) + 'px',
                'height': Math.floor($imgWrapper.height() / 2) + 'px',
                'data': {id: $element.find('.video-card').data('id')}
            });

        if ($img.attr('src')) {
            $('<img/>', {
                'class' : 'draggable-item_img',
                'src' : $img.attr('src')
            }).appendTo($draggableItem);
        }

        return $draggableItem;
    },
    start: function () {
        logger.success(LOG_GROUP_DND, LOG_DRAG);
    }
};

/**
 * @private
 * @param {jQuery} $container
 * @return {boolean}
 */
var usingSortable = function($container) {
    var fetchType = $container.data("fetchtype"),
        enabledSortable = $container.data('enabled-sortable');

    return (fetchType === "ALBUM" || fetchType === 'album') && enabledSortable;
};

/**
 * @param {jQuery} $element
 */
function initDraggable($element) {
    import('jquery.ui.draggable').then(m => m && m.default || m).then(function () {
        $element.find('.js-draggable:not(.ui-draggable)').draggable(defaultDNDparams);
    });

    $element.off('dragstart');
    $element.on('dragstart', '.video-card_cnt:not(.ui-draggable)', function (e) {
        e.preventDefault();
    });
}

/**
 * @param {jQuery} $element
 */
function initDroppable($element) {
    var ownerType = $element.data('ownertype'),
        ownerRefId = $element.data('refid'),
        currentAlbumId = $element.data('albumid'),
        fetchType = $element.data('fetchtype'),
        wrapper = $('.video-page');

    import('jquery.ui.droppable').then(m => m && m.default || m).then(function () {
        $element.find('.js-droppable:not(.ui-droppable)').droppable({
            tolerance: 'pointer',
            hoverClass: '__hover',
            drop: function (event, ui) {
                ui.draggable.dropped = true;
                var $this = $(this),
                    $videoCard = ui.draggable.find('.video-card'),
                    albumId = $this.data('id'),
                    movieId = $videoCard.data('id'),
                    blockId = $videoCard.data('bid') || movieId,
                    inLeftMenu = $this.hasClass('nav-side_i'),
                    successMovedMsg = wrapper.data('video-moved-msg'),
                    errorMovedMsg = wrapper.data('video-moved-error-msg'),
                    moveMovieUrl = wrapper.data('video-move-url') || URL_MOVE_MOVIE,
                    data = {
                        'st.mc.albumId': albumId,
                        'st.mc.id': movieId,
                        'st.mc.bid': blockId,
                        'st.mc.action': 'DRAG_N_DROP'
                    };

                // Specific parameters for group
                if (ownerType === 'GROUP') {
                    data['st.groupId'] = ownerRefId;
                    data['st.fetchType'] = fetchType;
                    if (currentAlbumId.length) {
                        data['st.albumId'] = currentAlbumId;
                    }
                }

                ajax({
                    url: moveMovieUrl,
                    data: data
                }).done(function (data, status, xhr) {
                    updateBlockModelCallback(data, status, xhr);
                    if (xhr.status === 200) {
                        showTip.call(wrapper, successMovedMsg, '__fixed');
                        logger.success(LOG_GROUP_DND, inLeftMenu ? LOG_DROP_LEFT_MENU : LOG_DROP_ALBUM);
                    } else {
                        showTip.call(wrapper, errorMovedMsg, '__fixed');
                        logger.error(LOG_GROUP_DND, LOG_DROP_ERROR);
                    }
                }.bind(this));
            }
        });
    });
}

/**
 * Показывает сообщение на карде.
 * @param {string} text
 * @param {string=} cssClass
 */
function showTip(text, cssClass) {
    var $card = $(this),
        $tip = $card.children('.tip');
    if (!$tip.length) {
        $tip = $('<div/>', {'class': 'tip __mid'});
        if (cssClass) {
            $tip.addClass(cssClass);
        }
        $tip.appendTo($card);
        $('<div/>', {'class': 'tip_cnt', text: text}).appendTo($tip);
    }

    var timeout = $tip.data('timeout');
    if (timeout) {
        clearTimeout(timeout);
        $tip.removeData('timeout');
    } else {
        setTimeout($.fn.addClass.bind($tip, '__active'), 10); // Ждем вставки элемента чтобы нормально сработала css-анимация
    }

    $tip.data('timeout', setTimeout(function() {
        $tip.removeClass('__active').removeData('timeout');
        setTimeout($.fn.remove.bind($tip), 150); // Ждем завершения css-анимации перед удалением элемента
    }, 2000));
}

/**
 * @param {jQuery.Event} e
 * @param {HTMLElement} scrollContainer
 * @param {boolean} correctForFirefox
 * @return {number}
 */
function extractScrollDelta(e, scrollContainer, correctForFirefox) {
    var origEvent = e.originalEvent,
        delta;

    // Old browsers support
    if (origEvent.detail) {
        delta = origEvent.detail;
    }
    if (origEvent.wheelDelta) {
        delta = origEvent.wheelDelta * -1;
    }
    if (origEvent.wheelDeltaY) {
        delta = origEvent.wheelDeltaY * -1;
    }

    // Modern browsers support
    if (origEvent.deltaY) {
        delta = origEvent.deltaY;
    }

    if (!delta) {
        return 0;
    }

    if (correctForFirefox === true) {
        // Fix delta for Mozilla Firefox
        // https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent#Delta_modes
        if (origEvent.deltaMode && origEvent.deltaMode === 1) {
            delta *= parseInt($(scrollContainer).css('fontSize'), 10);
        } else if (origEvent.deltaMode && origEvent.deltaMode === 2) {
            delta *= $(scrollContainer).height();
        }
    }

    return delta;
}

/**
 * @param {Element} container
 */
function removeWrapperComponent(container) {
    var closest = $(container).closest('.ugrid_i, .scroll-slider_item');
    if (closest.size() !== 0) {
        var parent = closest.parent();
        closest.remove();
        if (parent.children().size() === 0) {
            var vp = container.getAttribute("data-vp");
            var es = container.getAttribute("data-es");
            //удалено последнее видео в списке, покажем стаб:
            if (es) $(es).removeClass("invisible");
            if (vp) $(vp).addClass("invisible");
        }
    }
}

/**
 * @param {Element} container
 */
function activateSubscribeButton(container) {
    var $container = $(container),
        $subscribeButton = $container.find('.js-action-subscribe'),
        $unSubscribeButton = $container.find('.js-action-unsubscribe'),
        subscribeLink = $subscribeButton.data('subscribe'),
        unSubscribeLink = $unSubscribeButton.data('unsubscribe');
    $container.find('.js-subscribe').on('click', function () {
        var subscribed = !$subscribeButton.length || $subscribeButton.hasClass('invisible');
        ajax({url: subscribed ? unSubscribeLink : subscribeLink}).done(function (data, status, xhr) {
            var blocks = data.split(OK.navigation.SPLITER);
            var subscribeStatus = blocks[0];

            if (subscribeStatus === 'unSubscribed') {
                $subscribeButton.removeClass('invisible');
                $unSubscribeButton.addClass('invisible');
            } else if (subscribeStatus === 'subscribed') {
                $subscribeButton.addClass('invisible');
                $unSubscribeButton.removeClass('invisible');
            }

            if (blocks.length > 1) {
                updateBlockModelCallback(data, status, xhr);
            }
        });
        return false;
    });
}

/**
 * @param {Element} container
 */
function deactivateSubscribeButton(container) {
    $(container).find('.js-subscribe').off();
}

/**
 * @param {Element} container
 */
function activateDnd(container) {
    var $container = $(container),
        isChildContainer = $container.hasClass('js-dnd-child-cnt'),
        $mainContainer = isChildContainer ? $container.closest('.js-dnd-cnt') : $container;

    if (usingSortable($mainContainer)) {
        if (isChildContainer) {
            $mainContainer.sortable('refresh');
        } else {
            activateSortable($mainContainer);
        }
    } else {
        initDraggable($mainContainer);
    }
    initDroppable($mainContainer);
}

/**
 * @param {Element} container
 */
function deactivateDnd(container) {
    var $container = $(container),
        isChildContainer = $container.hasClass('js-dnd-child-cnt');
    if (!isChildContainer) {
        import('jquery.ui.droppable').then(m => m && m.default || m).then(function () {
            $container.find('.js-droppable.ui-droppable').droppable("disable");
        });
        $container.off('dragstart');

        if (usingSortable($container)) {
            deactivateSortable($container);
        } else {
            $container.find('.js-draggable.ui-draggable').draggable("disable");
        }
    }
}

/**
 * @param {jQuery} $container
 */
function activateSortable($container) {
    var albumId = $container.data('albumid');

    import('jquery.ui.sortable').then(m => m && m.default || m).then(function() {
        $container.sortable($.extend(defaultDNDparams, {
            items: '.js-sortable',
            revert: false,
            placeholder: 'ugrid_i sortable-placeholder __video',
            update: function(event, ui) {
                if (ui.item.dropped) {
                    return false;
                }

                var movieId = ui.item.find('.video-card').data('id'),
                    prevMovieId = ui.item.prev().find('.video-card').data('id'),
                    nextMovieId = ui.item.next().find('.video-card').data('id');

                $.ajax({
                    url: URL_SORT_MOVIE,
                    headers: {'TKN': OK.tkn.get()},
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        'st.vv_albumId': albumId,
                        'st.vv_movieId': movieId,
                        'st.vv_prevMovieId': prevMovieId,
                        'st.vv_nextMovieId': nextMovieId
                    }
                }).always(function(data) {
                    if (data.result) {
                        logger.success(LOG_GROUP_DND, LOG_SORT_SUCCESS);
                    } else {
                        logger.error(LOG_GROUP_DND, LOG_SORT_ERROR);
                    }
                });
            },
            start: function () {
                logger.success(LOG_GROUP_DND, LOG_SORT_START);
            }
        })).disableSelection();
    });
}

/**
 * @param {jQuery} $container
 */
function deactivateSortable($container) {
    $container.sortable("disable");
}

/**
 * @param {Element} container
 */
function activateRelatedVideo(container){
    var $container = $(container),
        blockId = $container.data('blockid'),
        checkpoints = $container.data('checkpoints'),
        currentPlayerId = $container.closest('.feed_cnt').find('.vid-card_cnt').data('player-element-id'),
        alreadyShowed = false;

    var onVideoEvent = function(e) {
        var activePlayerId = getPlayerId();

        if (e.detail && currentPlayerId === activePlayerId && checkpoints.indexOf(e.detail.name) !== -1 && !alreadyShowed) {

            if (e.detail.name === 'pause' && e.detail.data.statType === 'auto' && isSilent()) {
                // не обрабатываем искусственную паузу при автоплее
                return false;
            }

            ajax({
                url: URL_RENDER_RELATED,
                data: {
                    'st.rm.movieId': e.detail.data.movieId,
                    'st.rm.blockId': blockId
                }
            }).done(function(data, status, xhr) {
                updateBlockModelCallback(data, status, xhr);
                alreadyShowed = true;
                logger.success(LOG_RELATED, 'success');
            }).fail(function(){
                logger.error(LOG_RELATED, 'fail');
            });
        }
    };

    document.addEventListener('__videoPlayerEvent', onVideoEvent);

    $container.data('deactivateMethod', function() {
        document.removeEventListener('__videoPlayerEvent', onVideoEvent);
    });
}

function deactivateRelatedVideo(container){
    var $container = $(container),
        deactivateMethod = $container.data('deactivateMethod');
    deactivateMethod && deactivateMethod();
    $container.remove();
}

function activateHideLinkedElementOnChange(input) {
    var $input = $(input),
        linkedId = $input.data('linked-id'),
        $linked = $('#' + linkedId);

    $input.on('change', function() {
        $linked.toggleClass('invisible', $(this).attr('checked'));
    });
}

function deactivateHideLinkedElementOnChange(input) {
    $(input).off();
}

/*
 * @param options
 */
function activateOkTags(options) {
    var _options = Object.assign({
        inputCssSelector: '',
        wrapperCssSelector: '',
        tagCssClassName: 'movie-tag-field',
        newTagInputCssClass: 'tag_it',
        listClass: 'tags it vl_it vl_tags',
        textRemoveTagTitle: 'Удалить ключевое слово'
    }, options || {});

    if (!_options.inputCssSelector || !_options.wrapperCssSelector) {
        return;
    }

    var input = document.querySelector(_options.inputCssSelector);
    if (!input) {
        return;
    }

    var wrapper = $(_options.wrapperCssSelector);
    if (!wrapper.length) {
        return;
    }

    var name = input.name;
    var tags = [];

    if (input.value !== "") {
        tags = input.value.split(',').map(function (value) {
            return value.trim();
        });
    }

    wrapper.on('addedNewTag', '.' + _options.newTagInputCssClass, function (event, tag) {
        tags.push(tag);
        input.value = tags.join(',');
    });

    wrapper.on('removedTag', '.' + _options.newTagInputCssClass, function (event, tag) {
        var index = tags.indexOf(tag);
        if (index >= 0) {
            tags.splice(index, 1);
        }
        input.value = tags.join(',');
    });

    var documentFrag = document.createDocumentFragment();
    for (var j = tags.length || 1; j--;) {
        var _input = document.createElement('input');
        _input.name = name + '[]';
        _input.className = _options.tagCssClassName;
        _input.value = !!tags[j] ? tags[j] : "";

        documentFrag.appendChild(_input);
    }
    input.parentNode.insertBefore(documentFrag, input.nextSibling);

    $('.' + _options.tagCssClassName).okTags({
        listClass: _options.listClass,
        inputClass: _options.newTagInputCssClass,
        textRemoveLinkTitle: _options.textRemoveTagTitle
    });
}

function deactivateOkTags(options) {
    if (options && options.wrapperCssSelector) {
        var wrapper = $(options.wrapperCssSelector);
        if (wrapper.length) {
            wrapper.off();
        }
    }
}

function trackVideoPage() {
    if (window.location.pathname === '/video/sport') {
        myTrackerService.trackCustomGoal('open_sport_page_broadcast');
    }
}

export default { extractScrollDelta, removeWrapperComponent, activateSubscribeButton, deactivateSubscribeButton, activateDnd, deactivateDnd, activateSortable, deactivateSortable, showTip, activateRelatedVideo, deactivateRelatedVideo, activateHideLinkedElementOnChange, deactivateHideLinkedElementOnChange, activateOkTags, deactivateOkTags, trackVideoPage };

export { extractScrollDelta, removeWrapperComponent, activateSubscribeButton, deactivateSubscribeButton, activateDnd, deactivateDnd, activateSortable, deactivateSortable, showTip, activateRelatedVideo, deactivateRelatedVideo, activateHideLinkedElementOnChange, deactivateHideLinkedElementOnChange, activateOkTags, deactivateOkTags, trackVideoPage };
